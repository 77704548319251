import 'alpinejs';

function init() {
  // INSERT STUFF IF NEEDED
  const tabsEls = document.querySelectorAll(".tabbed-cocktail-container");

  [...tabsEls].forEach((tabsEl, idx) => {

    //var required_height = tabsEl.scrollHeight;
    //tabsEl.style.minHeight = required_height + "px";
    //console.log(required_height);

    const tab1 = tabsEl.querySelector(".tab--1");
    const tab2 = tabsEl.querySelector(".tab--2");
    const tabContent = tabsEl.querySelector(".tabs-content");

    //var required_height = tabContent.scrollHeight;

    var required_height1 = tab1.scrollHeight;
    var required_height2 = tab2.scrollHeight;

    console.log(required_height1, required_height2);

    if (required_height1 >= required_height2) {
      tabsEl.style.minHeight = required_height1 + "px";
      tab1.style.minHeight = required_height1 + "px";
      tab2.style.minHeight = required_height1 + "px";
    }
    else {
      tabsEl.style.minHeight = required_height2 + "px";
      tab1.style.minHeight = required_height2 + "px";
      tab2.style.minHeight = required_height2 + "px";
    }

    //tabsEl.style.minHeight = required_height;

    /*

    const tab1 = tabsEl.querySelector(".tab--1");
    const tab2 = tabsEl.querySelector(".tab--2");
    const tabContent = tabsEl.querySelector(".tabs-content");

    var required_height1 = tab1.scrollHeight;
    var required_height2 = tab2.scrollHeight;

    if (required_height1 >= required_height2) {
      tabsEl.style.minHeight = required_height1 + "px";
    }
    else {
      tabsEl.style.minHeight = required_height2 + "px";
    }
    */


  });


}

export default {
  init,
};
