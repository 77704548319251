function init() {

  window.addEventListener('agegatepassed', function() {
    if ( window.location.hash ) {
      let hash = window.location.hash;
      let elem_id = hash.substring(1);
      const element = document.getElementById( elem_id );

      if ( element ) {
        setTimeout(function(){
          element.scrollIntoView();
        }, 500);
      }
    }
  });
}

export default {
  init,
};
